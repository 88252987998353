/**
 * Constructs a URL for a fallback avatar image based on user initials.
 *
 * This function generates a URL for an avatar image using the first letters of
 * the user's first and last names. The resulting image is a gradient SVG with
 * the user's initials.
 *
 * @param firstName - The user's first name.
 * @param lastName - The user's last name.
 * @returns A string containing the URL for the fallback avatar image.
 *
 * @example
 * const avatarUrl = constructFallbackAvatarUrl("John", "Doe");
 * // Returns: "https://avatars.soillink.app/9.x/initials/svg?backgroundColor=12736f,c80846,0067af&backgroundType=gradientLinear&seed=jd"
 */
export function constructFallbackAvatarUrlInitials(firstName: string, lastName: string): string {
  // Construct user initials from the first characters of first and last name
  const userInitials = firstName.charAt(0).toLowerCase() + lastName.charAt(0).toLowerCase()

  // Return the constructed URL with user initials as the seed
  return `https://avatars.soillink.app/9.x/initials/svg?backgroundColor=12736f,c80846,0067af&backgroundType=gradientLinear&seed=${userInitials}`
}

/**
 * Constructs a URL for a fallback avatar image based on user initials.
 *
 * This function generates a URL for an avatar image using the first letters of
 * each part of the user's full name. The resulting image is a gradient SVG with
 * the user's initials.
 *
 * @param fullName - The user's full name.
 * @returns A string containing the URL for the fallback avatar image.
 *
 * @example
 * const avatarUrl = constructFallbackAvatarUrl("John Doe");
 * // Returns: "https://avatars.soillink.app/9.x/initials/svg?backgroundColor=12736f,c80846,0067af&backgroundType=gradientLinear&seed=jd"
 *
 * @example
 * const avatarUrl = constructFallbackAvatarUrl("Alice Bob Charlie");
 * // Returns: "https://avatars.soillink.app/9.x/initials/svg?backgroundColor=12736f,c80846,0067af&backgroundType=gradientLinear&seed=abc"
 */
export function constructFallbackAvatarUrl(fullName: string): string {
  // Split the full name by spaces
  const nameParts = fullName.split(' ')

  // Get the first character of each part and join them
  const userInitials = nameParts
    .map(part => part.charAt(0).toLowerCase())
    .join('')

  // Return the constructed URL with user initials as the seed
  return `https://avatars.soillink.app/9.x/initials/svg?backgroundColor=12736f,c80846,0067af&backgroundType=gradientLinear&seed=${userInitials}`
}
